<template>
  <div class="CategoryTypeList">
    <!-- No grouped types to show -->
    <div
      v-if="!groupedTypes.length"
      class="EmptyList">
      <span>{{ mixWB('NOTHING_ADDED_YET') }}</span>
    </div>

    <!-- Grouped types to show -->
    <div
      v-for="group in groupedTypes"
      :key="group.groupID"
      class="GroupedTypes"
      :class="groupClasses">
      <CategoryTypeListItem
        v-for="(typeItem, index) in group.typeItems"
        :key="typeItem.type.id"
        :type="typeItem.type"
        :cantBeUsed="!!typeItem.cantBeUsed"
        :clickToEdit="!overlayData"
        :outerInterConnection="typeItem.outerInterConnection || false"
        :interConnectionGroupID="group.groupID"
        :isFirstInGroup="index === 0"
        :isLastInGroup="index === group.typeItems.length - 1"
        :isCurrentGroup="group.isCurrentGroup"
        :showInnerSelect="!!overlayData.showInnerSelect"
        :showOuterSelect="!!overlayData.showOuterSelect" />
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import CategoryTypeListItem from '@/components/ScreeningItems/CategoryTypeListItem.vue'
import InterConnection from '@/globals/javascript/models/InterConnection'

export default {
  name: 'CategoryTypeList',
  props: {
    categoryID: {
      type: String,
      required: true,
    },
    overlayData: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'screeningTypes',
      'screeningInterConnections',
      'currentScreeningSelectedFilterUnitID',
    ]),
    groupClasses() {
      return {
        ShowOuterSelect: this.overlayData?.showOuterSelect,
        ShowInnerSelect: this.overlayData?.showInnerSelect,
      }
    },
    allInterConnections() {
      return this.overlayData?.allInterConnections ?? this.screeningInterConnections
    },
    allScreeningTypes() {
      return this.overlayData?.allScreeningTypes ?? this.screeningTypes
    },
    groupedTypes() {
      // Find inter connection groups
      const usedInterConnectionGroupIDs = []
      const usedTypeIDs = []
      const groupedTypes = []
      this.allInterConnections.forEach((interConnection) => {
        if (usedInterConnectionGroupIDs.includes(interConnection.groupID)) {
          return
        }
        usedInterConnectionGroupIDs.push(interConnection.groupID)

        const firstType = this.allScreeningTypes.find((x) => x.id === interConnection.innerTypeID)
        usedTypeIDs.push(firstType.id)

        const groupData = {
          groupID: interConnection.groupID,
          isCurrentGroup: false,
          typeItems: [{
            type: firstType,
            innerInterConnection: null,
            outerInterConnection: null,
          }],
          lastUpdated: firstType.lastUpdated.seconds,
        }

        if (firstType.id === this.overlayData?.typeData?.id) {
          groupData.isCurrentGroup = true
        }

        // Find all inner connections
        let nextInnerItem = this.allInterConnections.find(
          (x) => x.outerTypeID === firstType.id
            && x.groupID === groupData.groupID,
        )
        while (nextInnerItem) {
          groupData.typeItems[0].innerInterConnection = nextInnerItem
          // eslint-disable-next-line no-loop-func
          const type = this.allScreeningTypes.find((x) => x.id === nextInnerItem.innerTypeID)
          usedTypeIDs.push(type.id)
          groupData.typeItems.unshift({
            type,
            innerInterConnection: null,
            outerInterConnection: nextInnerItem,
          })
          if (type.id === this.overlayData?.typeData?.id) {
            groupData.isCurrentGroup = true
          }
          if (type.lastUpdated.seconds > groupData.lastUpdated) {
            groupData.lastUpdated = type.lastUpdated.seconds
          }
          nextInnerItem = this.allInterConnections.find(
            // eslint-disable-next-line no-loop-func
            (x) => x.outerTypeID === nextInnerItem.innerTypeID
              && x.groupID === groupData.groupID,
          )
        }

        // Find all outer connections
        let nextOuterItem = this.allInterConnections.find(
          (x) => x.innerTypeID === firstType.id
            && x.groupID === groupData.groupID,
        )
        while (nextOuterItem) {
          const lastItem = groupData.typeItems[groupData.typeItems.length - 1]
          lastItem.outerInterConnection = nextOuterItem
          // eslint-disable-next-line no-loop-func
          const type = this.allScreeningTypes.find((x) => x.id === nextOuterItem.outerTypeID)
          usedTypeIDs.push(type.id)
          groupData.typeItems.push({
            type,
            innerInterConnection: nextOuterItem,
            outerInterConnection: null,
          })
          if (type.id === this.overlayData?.typeData?.id) {
            groupData.isCurrentGroup = true
          }
          if (type.lastUpdated.seconds > groupData.lastUpdated) {
            groupData.lastUpdated = type.lastUpdated.seconds
          }
          nextOuterItem = this.allInterConnections.find(
            // eslint-disable-next-line no-loop-func
            (x) => x.innerTypeID === nextOuterItem.outerTypeID
              && x.groupID === groupData.groupID,
          )
        }

        // Check to show for selected category
        if (!groupData.typeItems.find(
          (x) => x.type.categoryID === this.categoryID,
        )) {
          return
        }

        // Check to only show with matching units
        if (this.overlayData) {
          let isAnyAllowed = false
          groupData.typeItems.forEach((typeItem) => {
            let matchFound = false
            if (this.overlayData.showInnerSelect) {
              matchFound = InterConnection.checkInterConnectionUnits(
                typeItem.type,
                this.overlayData.typeData,
              )
            }
            else {
              matchFound = InterConnection.checkInterConnectionUnits(
                this.overlayData.typeData,
                typeItem.type,
              )
            }

            if (matchFound) {
              isAnyAllowed = true
            }
            else {
              typeItem.cantBeUsed = true
            }
          })

          if (!isAnyAllowed) {
            return
          }
        }

        // Only show groups with types that has the selected unit
        else if (this.currentScreeningSelectedFilterUnitID) {
          let isAnyAllowed = false
          groupData.typeItems.forEach((typeItem) => {
            if (typeItem.type.unitIDs.includes(this.currentScreeningSelectedFilterUnitID)) {
              isAnyAllowed = true
            }
          })

          if (!isAnyAllowed) {
            return
          }
        }

        groupedTypes.push(groupData)
      })

      // Add rest of types
      this.allScreeningTypes.forEach((type) => {
        // Check if already used in a group and is not set to be its own type as well
        if (usedTypeIDs.includes(type.id) && !type.isOwnTypeAsWell) {
          return
        }

        // Check to show for selected category
        if (type.categoryID !== this.categoryID) {
          return
        }

        if (this.overlayData) {
          let matchFound = false
          if (this.overlayData.showInnerSelect) {
            matchFound = InterConnection.checkInterConnectionUnits(
              type,
              this.overlayData.typeData,
            )
          }
          else {
            matchFound = InterConnection.checkInterConnectionUnits(
              this.overlayData.typeData,
              type,
            )
          }

          if (!matchFound) {
            return
          }
        }

        // Only show types that has the selected unit
        else if (this.currentScreeningSelectedFilterUnitID) {
          if (!type.unitIDs.includes(this.currentScreeningSelectedFilterUnitID)) {
            return
          }
        }

        groupedTypes.push({
          groupID: `single-${ type.id }`,
          isCurrentGroup: type.id === this.overlayData?.typeData?.id,
          typeItems: [{
            type,
            innerInterConnection: null,
            outerInterConnection: null,
          }],
          lastUpdated: type.lastUpdated.seconds,
        })
      })

      return sortBy(groupedTypes, 'lastUpdated')
    },
  },
  components: {
    CategoryTypeListItem,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypeList
    display block

  .EmptyList
    margin-bottom 20px
    span
      font-style italic
      color $color_grey

  .GroupedTypes
    position relative
    margin-bottom 15px
    box-shadow 1px 2px 3px rgba(0, 0, 0, 0.2)
    &.ShowOuterSelect
      margin-top 30px
      margin-bottom 0
    &.ShowInnerSelect
      margin-bottom 30px
    > div
      border-bottom 1px solid $color_grey_lighter
      &:last-child
        border-bottom none
</style>
