<template>
  <div class="EffectDirectionMenu">
    <DotMenuItem
      :text="mixWB('NONE')"
      icon="none"
      itemID="none"
      size="small"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('BOTH')"
      icon="both"
      itemID="both"
      size="small"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('LEFT')"
      icon="left"
      itemID="left"
      size="small"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('RIGHT')"
      icon="right"
      itemID="right"
      size="small"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'

export default {
  name: 'EffectDirectionMenu',
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'item-selected', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },
}
</script>

<style lang="stylus" scoped>
  .EffectDirectionMenu
    display block
</style>
