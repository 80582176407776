<template>
  <div class="SplitStatusMenu">
    <DotMenuItem
      :text="mixWB('STUCK')"
      icon="lock"
      itemID="stuck"
      size="small"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('SPLITABLE')"
      icon="split"
      itemID="splitable"
      size="small"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'

export default {
  name: 'SplitStatusMenu',
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'item-selected', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },
}
</script>

<style lang="stylus" scoped>
  .SplitStatusMenu
    display block
</style>
