<template>
  <div
    :class="rootClasses"
    class="InterConnectionIcons">

    <!-- Split status -->
    <div
      class="ConnectionTypeIconWrap"
      :class="{ 'IsLocked': interConnection.isSplitStatusLocked && useSplitStatusMenu }"
      @click.stop="onConnectionTypeIconClick">
      <div
        v-if="interConnection.splitStatus === 'stuck'"
        class="ConnectionTypeIcon">
        <LockIcon />
      </div>
      <div
        v-else-if="interConnection.splitStatus === 'splitable'"
        class="ConnectionTypeIcon"
        :class="{ SplitAble: interConnection.splitStatus === 'splitable' }">
        <SplitIcon />
      </div>
      <div
        v-else
        class="ConnectionTypeIcon Missing">
        <QuestionIcon/>
      </div>

      <!-- Connection menu -->
      <DotMenu
        :use-component="SplitStatusMenu"
        :menuOpenTrigger="connectionMenuOpenTrigger"
        type="hidden"
        size="small"
        position="normal"
        :title="mixWB('SEPARATION')"
        scrollOnOpen
        @item-selected="onConnectionItemSelected" />
    </div>

    <!-- Effect direction -->
    <div
      v-if="interConnection.splitStatus === 'splitable'"
      class="EffectTypeIconWrap"
      :class="{
        'IsLocked': interConnection.isSplitStatusLocked
          && !interConnection.isCleanableConnection
          && useSplitStatusMenu
      }"
      @click.stop="onEffectTypeIconClick">
      <div
        v-if="interConnection.effectDirection === 'none'"
        class="ConnectionEffectIcon">
        <NoneIcon />
      </div>
      <div
        v-else-if="interConnection.effectDirection === 'both'"
        class="ConnectionEffectIcon Both">
        <BothIcon />
      </div>
      <div
        v-else-if="interConnection.effectDirection === 'left'"
        class="ConnectionEffectIcon Left">
        <ArrowRightIcon />
      </div>
      <div
        v-else-if="interConnection.effectDirection === 'right'"
        class="ConnectionEffectIcon Right">
        <ArrowRightIcon />
      </div>
      <div
        v-else
        class="ConnectionEffectIcon Missing">
        <QuestionIcon/>
      </div>

      <!-- Effect menu -->
      <DotMenu
        :use-component="EffectDirectionMenu"
        :menuOpenTrigger="effectMenuOpenTrigger"
        type="hidden"
        size="small"
        position="normal"
        :title="mixWB('INFECTION_ROUTE')"
        scrollOnOpen
        @item-selected="onEffectItemSelected" />
    </div>
  </div>
</template>

<script>
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import SplitStatusMenu from '@/components/ScreeningItems/Menus/SplitStatusMenu.vue'
import EffectDirectionMenu from '@/components/ScreeningItems/Menus/EffectDirectionMenu.vue'
import LockIcon from '@/assets/svg/lock.svg?inline'
import SplitIcon from '@/assets/svg/split.svg?inline'
import QuestionIcon from '@/assets/svg/question.svg?inline'
import NoneIcon from '@/assets/svg/none.svg?inline'
import BothIcon from '@/assets/svg/both-ways.svg?inline'
import ArrowRightIcon from '@/assets/svg/arrow-right.svg?inline'

export default {
  name: 'InterConnectionIcons',
  props: {
    interConnection: {
      type: Object,
      required: true,
    },
    isVertical: {
      type: Boolean,
      required: false,
      default: false,
    },
    useSplitStatusMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      SplitStatusMenu,
      EffectDirectionMenu,
      connectionMenuOpenTrigger: 0,
      effectMenuOpenTrigger: 0,
    }
  },
  computed: {
    rootClasses() {
      return {
        Horizontal: !this.isVertical,
        Vertical: this.isVertical,
        HasMenu: this.useSplitStatusMenu,
      }
    },
  },
  methods: {
    onConnectionTypeIconClick() {
      if (!this.useSplitStatusMenu) {
        return
      }

      if (this.interConnection.isSplitStatusLocked) {
        return
      }

      requestAnimationFrame(() => {
        this.connectionMenuOpenTrigger += 1
      })
    },
    onEffectTypeIconClick() {
      if (!this.useSplitStatusMenu) {
        return
      }

      if (this.interConnection.isSplitStatusLocked && !this.interConnection.isCleanableConnection) {
        return
      }

      requestAnimationFrame(() => {
        this.effectMenuOpenTrigger += 1
      })
    },
    onConnectionItemSelected(status) {
      const autoOpenEffectDirectionMenu = status === 'splitable'
        && this.interConnection.splitStatus !== 'splitable'

      this.$emit('split-status-selected', status)

      if (autoOpenEffectDirectionMenu) {
        this.onEffectTypeIconClick()
      }
    },
    onEffectItemSelected(status) {
      this.$emit('effect-status-selected', status)
    },
    checkToOpenEffectDirectionMenuOnLoad() {
      if (this.interConnection.isCleanableConnection && !this.interConnection.effectDirection) {
        this.onEffectTypeIconClick()
      }
    },
  },
  components: {
    DotMenu,
    LockIcon,
    SplitIcon,
    QuestionIcon,
    NoneIcon,
    BothIcon,
    ArrowRightIcon,
  },
  mounted() {
    this.checkToOpenEffectDirectionMenuOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .InterConnectionIcons
    .ConnectionTypeIconWrap,
    .EffectTypeIconWrap
      position relative
      .ConnectionTypeIcon
        circle(32px)
        padding 7px
        background-color $color_inter_connection
        box-shadow $box_shadow_1
        svg
          fill #fff
        &.Missing
          background-color lighten($color_inter_connection, 50%)
          svg
            fill $color_error
      .ConnectionEffectIcon
        circle(32px)
        padding 7px
        background-color $color_inter_connection_effect
        box-shadow $box_shadow_1
        svg
          fill #fff
        &.Missing
          background-color lighten($color_inter_connection_effect, 50%)
          svg
            fill $color_error
      &.IsLocked
        .ConnectionTypeIcon,
        .ConnectionEffectIcon
          background-color $color_grey_light
          cursor default
    &.HasMenu
      .ConnectionTypeIcon
        cursor pointer
    &.Horizontal
      box(84px, 32px)
      display flex
      justify-content center
      .ConnectionTypeIcon
        margin 0px 5px
        &.SplitAble
          svg
            transform rotate(90deg)
      .ConnectionEffectIcon
        margin 0px 5px
        &.Right
          svg
            transform rotate(90deg)
        &.Left
          svg
            transform rotate(-90deg)
    &.Vertical
      box(32px, 84px)
      flex-center-children-column()
      justify-content center
      .ConnectionTypeIcon
        margin 5px 0px
      .ConnectionEffectIcon
        margin 5px 0px
        &.Both
          svg
            transform rotate(90deg)
        &.Left
          svg
            transform rotate(-180deg)

</style>
