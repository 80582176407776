<template>
  <div class="AddedTypeMenu">
    <DotMenuItem
      :text="mixWB('DELETE_TYPE')"
      itemID="delete"
      color="red"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'

export default {
  name: 'AddedTypeMenu',
  methods: {
    onDotItemClick(itemID) {
      if (itemID === 'delete') {
        this.$emit('pass-to-parent', 'delete-type-click')
      }
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },
}
</script>

<style lang="stylus" scoped>
</style>
