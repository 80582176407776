<template>
  <div
    class="CategoryTypeListItem"
    :class="rootClasses"
    @click="onItemClick">
    <div class="ImageWrap">
      <MultiImage
        v-if="type.images[0]"
        :image="type.images[0]" />
    </div>

    <div class="InfoWrap">
      <span
        v-if="isConnectedType"
        class="Title">{{ type.getTitle({ category: true }) }}</span>
      <span
        v-else
        class="Title">{{ type.getTitle() }}</span>
      <span v-if="detailsText">{{ detailsText }}</span>
      <span>{{ type.getUnitsList() }}</span>
      <template v-if="type.resale && type.resale !== 'no'">
        <span>
          {{ mixWB('RESALE') }}: {{ type.resale === 'yes' ? mixWB('YES') : mixWB('POTENTIAL') }}
        </span>
      </template>
      <template v-if="getAddedTypeAnalyzesText()">
        <span class="Analyzes">{{ getAddedTypeAnalyzesText() }}</span>
      </template>
      <template v-if="getAutoAssessmentsText()">
        <span class="Assessments">{{ getAutoAssessmentsText() }}</span>
      </template>
    </div>

    <!-- Item menu -->
    <div
      v-if="clickToEdit"
      class="DotMenuWrap">
      <DotMenu
        :use-component="AddedTypeMenu"
        position="normal"
        @delete-type-click="onDeleteTypeClick()" />
    </div>

    <!-- Outer inter connection -->
    <div
      v-if="outerInterConnection && clickToEdit"
      class="InterConnectionIconsWrap"
      @click.stop>
      <InterConnectionIcons
        :interConnection="outerInterConnection"
        :type="type" />
    </div>

    <!-- Inner selectors -->
    <div
      v-if="!isCurrentGroup && showInnerSelect && !cantBeUsed"
      class="SelectersWrap Inner"
      :class="{ IsLastInGroup: isLastInGroup, IsFirstInGroup: isFirstInGroup }">
      <div
        class="SelectItem"
        @click="onSelectClick('inner', 'single')">
        <div class="IconWrap Single">
          <SingleTypeIcon />
        </div>
      </div>
      <div
        v-if="!isFirstInGroup"
        class="SelectItem"
        @click="onSelectClick('inner', 'all')">
        <div class="IconWrap Inner All">
          <AllTypesIcon />
        </div>
      </div>
    </div>

    <!-- Outer selectors -->
    <div
      v-if="!isCurrentGroup && showOuterSelect && !cantBeUsed"
      class="SelectersWrap Outer"
      :class="{ IsLastInGroup: isLastInGroup, IsFirstInGroup: isFirstInGroup }">
      <div class="SelectItem">
        <div
          class="IconWrap Single"
          @click="onSelectClick('outer', 'single')">
          <SingleTypeIcon />
        </div>
      </div>
      <div
        v-if="!isLastInGroup"
        class="SelectItem"
        @click="onSelectClick('outer', 'all')">
        <div class="IconWrap Outer All">
          <AllTypesIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage.vue'
import AllTypesIcon from '@/assets/svg/all-types.svg?inline'
import SingleTypeIcon from '@/assets/svg/single-type.svg?inline'
import EventBus from '@/EventBus'
import InterConnectionIcons from '@/components/ScreeningItems/InterConnectionIcons.vue'
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import AddedTypeMenu from '@/components/ScreeningItems/Menus/AddedTypeMenu.vue'

export default {
  name: 'CategoryTypeListItem',
  props: {
    type: {
      type: Object,
      required: true,
    },
    cantBeUsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickToEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    outerInterConnection: {
      type: [Object, Boolean],
      required: true,
      default: false,
    },
    interConnectionGroupID: {
      type: String,
      required: true,
    },
    isFirstInGroup: {
      type: Boolean,
      required: true,
    },
    isLastInGroup: {
      type: Boolean,
      required: true,
    },
    isCurrentGroup: {
      type: Boolean,
      required: true,
    },
    showInnerSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOuterSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    isConnectedType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      AddedTypeMenu,
    }
  },
  computed: {
    rootClasses() {
      return {
        ClickToEdit: this.clickToEdit,
        IsCurrentGroup: this.isCurrentGroup,
      }
    },
    detailsText() {
      let text = ''

      // Joints and adhesives
      if (this.type.hasTags({ tags: ['TAG-2'] })) {
        text += this.mixWB('JOINTS_AND_ADHESIVES')
      }

      // Coating
      if (this.type.getCoatingText()) {
        text += `${ text ? ' + ' : '' }${ this.type.getCoatingText() }`
      }

      return text
    },
  },
  methods: {
    getAddedTypeAnalyzesText() {
      if (this.type.resale === 'yes') {
        return false
      }

      let noOfSamples = 0
      let noOfAnalyzes = 0

      if (this.type.samples.sampleIDs.length) {
        noOfSamples += 1
        noOfAnalyzes += this.type.samples.sampleIDs.length
      }
      if (this.type.coating.samples.sampleIDs.length) {
        noOfSamples += 1
        noOfAnalyzes += this.type.coating.samples.sampleIDs.length
      }
      if (this.type.underlining.samples.sampleIDs.length) {
        noOfSamples += 1
        noOfAnalyzes += this.type.underlining.samples.sampleIDs.length
      }

      if (!noOfSamples) {
        return false
      }

      return `${
        noOfSamples
      } ${
        noOfSamples === 1
          ? this.mixWB('SAMPLE')
          : this.mixWB('SAMPLES')
      } - ${
        noOfAnalyzes
      } ${
        noOfAnalyzes === 1
          ? this.mixWB('ANALYSIS').toLowerCase()
          : this.mixWB('ANALYZES').toLowerCase()
      }`
    },
    getAutoAssessmentsText() {
      if (this.type.resale === 'yes') {
        return false
      }

      if (!this.type.assessments.assessmentIDs.length) {
        return false
      }

      return `${
        this.type.assessments.assessmentIDs.length
      } ${
        this.type.assessments.assessmentIDs.length === 1
          ? this.mixWB('ASSESSMENT').toLowerCase()
          : this.mixWB('ASSESSMENTS').toLowerCase()
      } (${
        this.type.getAssessmentList()
      })`
    },
    onItemClick() {
      if (!this.clickToEdit) {
        return
      }

      this.$router.push({
        name: 'ScreeningsUpdateCategoryEditType',
        params: { categoryID: this.type.categoryID, addedTypeID: this.type.id },
      })
    },
    onDeleteTypeClick() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      // Delete type
      this.$store.dispatch('deleteAddedType', this.type.id)
    },
    onSelectClick(side, choice) {
      EventBus.$emit('inter-connection-select', {
        side,
        choice,
        interConnectionGroupID: this.interConnectionGroupID.includes('single-')
          ? ''
          : this.interConnectionGroupID,
        typeID: this.type.id,
        isFirstInGroup: this.isFirstInGroup,
        isLastInGroup: this.isLastInGroup,
      })
    },
  },
  components: {
    MultiImage,
    AllTypesIcon,
    SingleTypeIcon,
    InterConnectionIcons,
    DotMenu,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypeListItem
    position relative
    display flex
    background-color $color_grey_lightest
    &.IsCurrentGroup
      background-color $color_primary_light
    &.ClickToEdit
      cursor pointer

  .ImageWrap
    position relative
    flex-shrink 0
    box(100px)
    padding 5px
    margin-right 5px

  .InfoWrap
    position relative
    padding-top 7.5px
    width calc(100% - 100px - 45px)
    span
      truncated()
      font-size 0.75rem
      padding-bottom 2px
    .Title
      text-transform uppercase
      margin-bottom 2.5px
      color $color_grey_dark
      font-size 0.875rem
    .Analyzes
        color $color_analyzes
    .Assessments
      color $color_assessments

  .DotMenuWrap
    flex-center-children()
    flex-shrink 0

  .InterConnectionIconsWrap
    position absolute
    z-index 1
    bottom -16px
    left 8px

  .SelectersWrap
    position absolute
    z-index 1
    flex-center-children-column()
    justify-content center
    box(40px, 80px)
    &.Inner
      right -15px
      bottom calc(50% - 40px)
      &.IsLastInGroup
        flex-direction row
        box(80px, 40px)
        bottom -20px
        left calc(50% - 40px)
    &.Outer
      left -15px
      top calc(50% - 40px)
      &.IsFirstInGroup
        flex-direction row
        box(80px, 40px)
        top -20px
        left calc(50% - 40px)
      .IconWrap
        &.Single
          align-items flex-start
        svg
          transform rotate(180deg)
    .SelectItem
        box(40px)
        flex-center-children()
    .IconWrap
      overflow hidden
      circle(30px)
      box-shadow $box_shadow_1
      padding 2px
      flex-center-children()
      background-color $color_primary
      cursor pointer
      svg
        fill #fff
      &.All
        &.Inner
          align-items flex-start
          padding-top 0
        &.Outer
          align-items flex-end
          padding-bottom 0

</style>
